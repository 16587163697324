<template lang="pug">
div.flow-left
	div.academy__header.mb-6
		v-img.align-center(
			src="https://static.wintersport.nl/images/academy/ws-academy.jpg"
			gradient="rgba(37,63,86,.7), rgba(2,11,31,.7)"
			position="50%"
			width="100%"
			)
	ws-content(:value="contentpage.content" )
		template(slot="levels")
			v-card.mb-6(outlined v-for="level in levels" v-if="level.isDefault" v-bind:key="`${level.id}_${level.slug}`")
				v-container.pa-3(grid-list-lg)
					v-row
						v-col(cols="12" md="4")
							nuxt-link(:to="localePath({name:'academy-level', params: {level: level.slug}})")
								v-img.play-btn(:aspect-ratio="16/9" :src="`//static.wintersport.nl/images/academy/ws-academy-${level.slug}.jpg`" :alt="level.name")
						v-col(cols="12" md="8")
							h3.mb-0 {{level.name}}
							span.caption.grey--text {{level.chaptersCount}} hoofdstukken, {{level.lessonsCount}} videos
							p.pa-0 {{level.intro}}
								nuxt-link.text-no-wrap(:to="localePath({name:'academy-level', params: {level: level.slug}})") &nbsp;&raquo; Start nu!

		template(slot="sneeuwfit")
			v-card.mb-6(outlined v-for="level in levels" v-if="level.isDefault == false" :key="`${level.id}_${level.slug}`")
				v-container.pa-3(grid-list-lg)
					v-row
						v-col(cols="12" md="4")
							nuxt-link(:to="localePath({name:'academy-level', params: {level: level.slug}})")
								v-img.play-btn(:aspect-ratio="16/9" :src="`//static.wintersport.nl/images/academy/ws-academy-${level.slug}.jpg`" :alt="level.name")
						v-col(cols="12" md="8")
							h3.mb-0 {{level.name}}
							span.caption.grey--text {{level.chaptersCount}} hoofdstukken, {{level.lessonsCount}} videos
							p.pa-0 {{level.intro}}
								nuxt-link.text-no-wrap(:to="localePath({name:'academy-level', params: {level: level.slug}})") &nbsp;&raquo; Start nu!

	h2 Wintersport in de buurt
	p Bij het woord wintersport denk je al snel aan de Alpen, maar wist je dat je ook in de buurt prima kunt wintersporten? Door het hele land zijn verschillende organisaties te vinden waar je het hele jaar door terecht kunt voor het nodige wintersportplezier. Met onze zoekmachine vind je eenvoudig de wintersportlocaties bij jou in de buurt.
	client-only
		mapbox(
			ref="map"
			style="height:400px"
			:layers="layers"
			:sources="sources"
			:fitbounds="markers.features.map(e=>e.geometry.coordinates)"
			@map-styleload="mapStyle")
			div(slot='popup')
				span.h3 {{popupData.name}}
</template>

<style lang="sass">
$break--large: 1439px

.academy__header
  h1
    font-size: 4rem
    margin-bottom: 2.4rem

@media (max-width: $break--large)
  .academy__header
    h1
      font-size: 3rem
</style>

<script>
import gql from 'graphql-tag';
import { ref } from '@vue/composition-api';
import MetaFragment from '@/components/graphql/metaFragment.graphql';
import useMap from '@/use/map';

const query = gql`
	query getAcademyIndexPage($path: String!) {
		contentpage(path: $path) {
			id
			name
			slug
			content {
				markdown
			}
			...MetaFragment
		}
		levels: academyLevels {
			id
			name
			slug
			intro
			content {
				markdown
			}
			video
			isDefault
			chaptersCount
			lessonsCount
			progress
		}
		localdestinations {
			id
			name
			slug
			geography {
				location {
					type
					coordinates
				}
			}
		}
	}
	${MetaFragment}
`;

export default {
	nuxtI18n: {
		paths: {
			'nl-NL': '/academy',
			'nl-BE': '/academy'
		}
	},
	components: {
		Mapbox: () => (process.client ? import('@/components/mapbox.vue') : null)
	},
	layout: 'default',
	setup() {
		const destinations = ref([]);
		const { markers, sources, layers, attachListeners, popupData } =
			useMap(destinations);

		return {
			destinations,
			layers,
			sources,
			markers,
			attachListeners,
			popupData
		};
	},
	asyncData({ _params, app, route }) {
		const client = app.$graphClient;
		return client
			.query({
				query,
				variables: {
					path: route.path
				}
			})
			.then(({ data }) => {
				app.meta(data.contentpage.meta);
				app.ads({});
				app.breadcrumbs({
					'Wintersport Academy': {
						name: 'academy'
					}
				});

				return data;
			})
			.catch(error => {
				return app.httpError(500, null, 'academy-01', error);
			});
	},
	head() {
		return this.$lodash.merge(this.$structuredData(), {
			script: [
				{
					innerHTML: JSON.stringify({
						'@context': 'https://schema.org/',
						'@type': 'Course',
						name: this.contentpage.meta.title,
						description: this.contentpage.meta.description,
						provider: {
							'@type': 'Organization',
							name: 'wintersport.nl'
						}
					}),
					type: 'application/ld+json',
					hid: 'product-schema'
				}
			]
		});
	},
	mounted() {
		this.destinations = this.localdestinations;
		if (this.$refs.map) {
			this.$nextTick(() => {
				this.$refs.map.fitToCurrentBounds();
			});
		}
	},
	methods: {
		mouseClick(feature) {
			this.$router.push(
				this.localePath({
					name: 'localdestinations-organisation',
					params: { organisation: feature.properties.slug }
				})
			);
		},
		mapStyle(map) {
			this.$refs.map.fitToCurrentBounds();
			this.attachListeners(
				map,
				this.$refs.map,
				'destination-markers',
				'geojson-markers',
				this.mouseClick
			);
		}
	}
};
</script>
