import { computed, ref } from '@vue/composition-api';

export default function useMap(items) {
	const popupData = ref({ name: '' });
	const hoveredFeature = ref(null);

	const markers = computed(() => {
		return {
			type: 'FeatureCollection',
			features: items.value
				.filter(destination => {
					return !!destination.geography && !!destination.geography.location;
				})
				.map((destination, index) => {
					return {
						type: 'Feature',
						id: index + 1,
						properties: {
							...destination,
							photo: destination.image
								? destination.image
								: destination.photos && destination.photos.length
								? destination.photos[0].url
								: '',
							rating:
								destination.reviews && !isNaN(destination.reviews.average)
									? destination.reviews.average
									: 0,
							reviewCount:
								destination.reviews && destination.reviews.voteCount
									? destination.reviews.voteCount // prop heet nu reviewCount maar toont de voteCount
									: 0,
							id: destination.id,
							coordinates: destination.geography
								? destination.geography.location.coordinates
								: '',
							partnerSlug: destination.partner && destination.partner.slug,
							to: destination.to || destination.url,
							icon:
								destination.icon ||
								destination.__typename.toLowerCase() + '-marker'
						},
						geometry: destination.geography
							? destination.geography.location
							: ''
					};
				})
		};
	});

	const layers = computed(() => {
		return [
			{
				id: 'destination-markers',
				type: 'circle',
				source: 'geojson-markers',
				layout: {
					visibility: 'visible'
				},
				paint: {
					'circle-radius': [
						'case',
						['boolean', ['feature-state', 'hover'], false],
						7,
						5
					],
					'circle-color': 'rgb(79, 164, 221)',
					'circle-stroke-color': '#fff',
					'circle-stroke-width': 2
				}
			}
		];
	});

	const sources = computed(() => {
		return {
			'geojson-markers': {
				type: 'geojson',
				data: markers.value
			}
		};
	});

	const attachListeners = (
		map,
		mapRef,
		layer,
		source = 'geojson-data',
		mouseClick = () => {}
	) => {
		map.on('mousemove', layer, e => {
			if (e.features.length > 0) {
				if (hoveredFeature.value) {
					map.setFeatureState(
						{
							source,
							id: hoveredFeature.value
						},
						{ hover: false }
					);

					mouseOut(mapRef);
				}
			}

			if (e.features[0].id) {
				hoveredFeature.value = e.features[0].id;

				map.setFeatureState(
					{
						source,
						id: hoveredFeature.value
					},
					{ hover: true }
				);
				mouseOver(e.features[0], mapRef);
			}
			map.getCanvas().style.cursor = hoveredFeature.value ? 'pointer' : '';
		});

		map.on('mouseleave', layer, () => {
			if (hoveredFeature.value) {
				map.setFeatureState(
					{
						source,
						id: hoveredFeature.value
					},
					{ hover: false }
				);

				mouseOut(mapRef);
			}
			hoveredFeature.value = null;
			map.getCanvas().style.cursor = hoveredFeature.value ? 'pointer' : '';
		});

		map.on('click', layer, e => {
			if (e.features.length > 0) {
				if (e.features[0].id) {
					mouseClick(e.features[0]);
				}
			}
		});
	};

	const mouseOver = (feature, map) => {
		popupData.value = feature.properties;
		map.showPopup(JSON.parse(feature.properties.coordinates));
	};

	const mouseOut = map => {
		popupData.value = {};
		map.hidePopup();
	};

	const mapOptions = computed(() => {
		if (items.value.length === 1) {
			return {
				center: items.value[0].geography.location.coordinates,
				zoom: 14,
				scrollZoom: false
			};
		} else {
			return undefined;
		}
	});

	return { markers, layers, sources, attachListeners, popupData, mapOptions };
}
